<template>
  <div class="flex flex-1 justify-center items-center relative">
    <div class="asset-viewer relative">
      <div class="flex flex-1 flex-col justify-center items-center w-full md:mt-4 md:p-4">
        <div class="asset-scene">
          <div class="asset-card" :class="{ 'is-flipped': isFlipped }" @click="toggleFlip">
            <img :src="imgFront" class="asset-card-face"  alt="front"/>
            <img :src="imgBack" class="asset-card-face asset-card-back" alt="back" v-if="hasBackImage" />
          </div>
        </div>
        <div class="mt-2 uppercase text-sm13 font-bold" v-if="hasBackImage">
          {{ $t('trade.click_to_rotate') }}
          <font-awesome-icon class="ml-1" icon="sync"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AssetViewer',

  props: {
    handleClose: Function,
    imgFront: [String, Boolean],
    imgBack: [String, Boolean],
  },

  data() {
    return {
      isFlipped: false,
    };
  },

  computed: {
    hasBackImage() {
      return !!this.imgBack;
    }
  },

  methods: {
    toggleFlip() {
      if (!this.hasBackImage) { return; }
      this.isFlipped = !this.isFlipped;
    }
  },

};
</script>

<style scoped>
  .asset-viewer {
    @apply flex;
    width: 460px;
    max-width: 100%;
    height: 752px;
    max-height: 80vh;
  }

  .asset-scene {
    width: 100%;
    height: 100%;
    perspective: 1500px;
  }

  .asset-card {
    width: 100%;
    height: 100%;
    transition: transform 900ms ease-in;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
  }

  .asset-card.is-flipped {
    transform: rotateY(180deg);
  }

  .asset-card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .asset-card-back {
    transform: rotateY(180deg);
  }
</style>
